@import "~bootstrap-sass/assets/stylesheets/bootstrap";

.content-wrapper { background-color: #fff; color: #454545; }

.blur_quiz{
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
}

.skin-green-light {
  .main-sidebar, .left-side {
    background-color: #ebebeb;
  }
  .wrapper{
    background-color: #fff;
  }

  .main-sidebar{
    border-right: 1px solid #243958;
  }
  .sidebar-menu {
    li.header {
      background-color: #ebebeb;
      color: #243958;
      font-weight: 400;
      font-size: 15px;
      text-transform: uppercase;
      margin-left: 2px;
    }
    li:hover{
      a {
        background-color: #fff;
        //color: white !important;
        -webkit-transition: background-color 0.4s, color 0.4s;
        -o-transition: background-color 0.4s, color 0.4s;
        -moz-transition: background-color 0.4s, color 0.4s;
        transition: background-color 0.4s, color 0.4s;
      }
    }
  }

  .main-header {
    .logo-lg{
      position: absolute;
      top: -2px;
      z-index: 1001;
    }
    .navbar, .logo, .dropdown-toggle {
      background-color: #243958;
      -webkit-transition: background-color 0.4s;
      -o-transition: background-color 0.4s;
      -moz-transition: background-color 0.4s;
      transition: background-color 0.4s;
    }
    .navbar .sidebar-toggle:hover, li.user-header, .dropdown-toggle:hover  {
      background-color: #243958;
      -webkit-transition: background-color 0.4s;
      -o-transition: background-color 0.4s;
      -moz-transition: background-color 0.4s;
      transition: background-color 0.4s;

      .support-logo{
        padding-right: 20px;
      }
    }
    .logo:hover{
      background-color: #20334f;
    }
  }
  .content-header{
       background: #f3f3f3;
       background: linear-gradient(to bottom, #ebebeb, #fff);
  }
}

h1, h2, h3, h4, h5, legend, .main-sidebar i {
    color: #243958;
    text-transform: uppercase;
    .widget-user-header & { text-transform: none; color: inherit; }
}

legend { border-color: #264869; }

h1 {
  font-weight: 600;
  .box-title{  padding-top: 20px; text-transform: none; color: #454545; }
}

a, .btn-link {
  color:#243958;
  -webkit-transition: color 0.4s;
  -o-transition: color 0.4s;
  -moz-transition: color 0.4s;
  transition: color 0.4s;
  &:hover, &:active, &:focus {
    color: #264869;
    text-decoration: none;
    -webkit-transition: color 0.4s;
    -o-transition: color 0.4s;
    -moz-transition: color 0.4s;
    transition: color 0.4s;
  }
}

.btn-file{position: relative; top: 0;}

.alert.parsley {
  margin-top: 5px;
  margin-bottom: 0px;
  padding: 10px 15px 10px 15px;
}

.parsley-custom-error-message{
  color: #b94949;
}

.check .alert {
  margin-top: 20px;
}
table {font-size: 14px;}
.datepicker {z-index: 1151 !important;}

#browserWarning { display:none; }
.info-box {  border: 1px solid #d2d6de; }

.box, .box-default {
  border: 1px solid #efefef;
  //border-top-width: 1px;
  &-header.with-border { border-bottom: 1px solid #efefef; }
  &-header &-title { color: #454545; }
  border-radius: 0;
}
.box-header, .box-body{
  border-radius: 0;
  .box-title{
    padding-left: 11px;
  }
  .box-footer{
    padding: 0;
    margin: 0;
    background-color: #f3f3f3;
  }
}

.box-wall{
  //margin-top: 25px;
  .panel-title{
    margin-top: 36px;
    color: #243958;
    margin-bottom: 2px;
    font-size: 22px;
    text-transform: capitalize;
  }
}
.elearning-text-red{
  color: #C13D50;
}
.elearning-text-green{
  color: #7fae4a;
}
.elearning-text-danger{
  color: #243958;
}
.elearning-text-info{
  color: #243958;
  font-size: 13px;
  font-style: italic;
  font-weight: 700;
  padding-left:13px;
  //text-align: left;
}
.admin-quiz-option{
  margin-bottom: 0 !important;
  padding-bottom:0 !important;
}
.admin-quiz-stats{
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.elearning_input_box{
  //border-radius: 20px !important;
}
.box-body{
  padding: 25px 0 0 0;
}

.modules-boxbody-row{
  li{
    padding-bottom: 7px;
  }
  .form-group{
    margin-bottom: 5px;
  }
}

.elearning-content{
  font-size: 15px !important;
  margin-top: 20px;
  margin-left: 3.5%;
  margin-right: 3.5%;
}

.box-body-top{
  padding-left: 20px;
  padding-right: 20px;
  font-size:15px;
}

.box-body-question{
  padding-bottom: 15px;
}

.box-header{
  background-color: #243958;
  .box-title{
    color: #FBFDFD;
  }
}
.elearning-exempt-text{
  margin-top: 12px;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  color: #243958;;
  text-align: center;
  //@media screen and (max-width: 1527px) and (min-width: 1200px){
  //  font-size: 10px;
  //}
}

.elearning-title-text{
  margin-top: 12px;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  color: #243958;
}

.btn-elearning-module{
  background-color: #EAC47A!important;
  border-color: #EAC47A!important;
  color: #514F49!important;
  height: 31px;
  line-height: 1.22857143;
}

.btn-elearning-module:hover {
  background-color: #243958!important;
  border-color: #243958!important;
  color: #f3f3f3!important;
}

.btn-elearning-disabled, .btn-elearning-disabled:hover{
  background-color: #f3f3f3!important;
  border-color: #f3f3f3!important;
  color: #d7d7d7!important;
}

.btn-elearning-dash{
  margin-top: 12px;
  margin-bottom: 12px;
}

.inner h4 { color: inherit; }

.user-header .badge { background-color: #FFF; color: #000; }

.parsley-required {color:#b94a48;}
.bg-red .modal-title,
.box-danger .box-title {
  color: #fff;
}
.required:after { content:" *";color:#b94a48; }
.hidden-input {visibility:hidden;width:0;padding: 0;border:none;}

section.sidebar-footer{
    height: 130px;
    width: inherit;
    background-color: #243958;
    color: #ffffff;
    position: fixed;
    bottom: 0;
}

section.sidebar-footer>a.fill-section{
  display: block;
  height: 100%;
  width: 100%;
}

section.sidebar{
    padding-bottom: 130px;
}

.powered-by-guild{
     text-transform: uppercase;
     font-weight: 500;
     font-size: 10px;
     text-align: center;
     padding-top: 15%;
     color: #ffffff;
 }

img.guild_logo{
    width: 50%;
    display: block;
    margin: auto;
}
.btn-primary,
.btn-primary:focus,
.dt-button,
.dt-button:focus{
    display: inline-block;
    background-color: #243958;
    border-color: #243958;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 15px;
    border-radius: 500px;
    margin-bottom: 12px;
    -webkit-transition: background-color 0.4s, border-color 0.4s;
    -o-transition: background-color 0.4s, border-color 0.4s;
    -moz-transition: background-color 0.4s, border-color 0.4s;
    transition: background-color 0.4s, border-color 0.4s;
}
.btn-primary:hover,
.btn-primary:active,
.dt-button:hover,
.dt-button:active{
    background-color: #264869;
    border-color: #264869;
    -webkit-transition: background-color 0.4s, border-color 0.4s;
    -o-transition: background-color 0.4s, border-color 0.4s;
    -moz-transition: background-color 0.4s, border-color 0.4s;
    transition: background-color 0.4s, border-color 0.4s;
}

.dt-buttons, .dataTables_length{
  display: inline-block;
  padding-right: 10px;
}

button.btn-default{
    display: inline-block;
    background-color: #d3d3d3;
    border-color: #d3d3d3;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 15px;
    border-radius: 500px;
    /*margin-bottom: 12px;*/
    -webkit-transition: background-color 0.4s, border-color 0.4s;
    -o-transition: background-color 0.4s, border-color 0.4s;
    -moz-transition: background-color 0.4s, border-color 0.4s;
    transition: background-color 0.4s, border-color 0.4s;
}
.btn-default:hover,
.btn-default:active{
    background-color: #efefef;
    border-color: #efefef;
    color: #ffffff;
    -webkit-transition: background-color 0.4s, border-color 0.4s;
    -o-transition: background-color 0.4s, border-color 0.4s;
    -moz-transition: background-color 0.4s, border-color 0.4s;
    transition: background-color 0.4s, border-color 0.4s;

}

.btn-auth{
    display: inline-block;
    width: 35%;
}

.btn-danger, .btn-warning, .btn-success{
    border-radius: 500px;
}

.button-header{
    margin-top: 12px;
}

.shaded {
    background: #f3f3f3;
  background: linear-gradient(to bottom, #f3f3f3, #fff);
}

.welcome-message {
    color: #444444;
    text-transform: none;
    font-weight: 800;
    font-size: 22px;
}

.home-content {
    text-align: center;
}

.page-title {
    text-transform: none;
    text-align: center;
    color: #243958;
 }
.page-description{
    text-align: center;
    padding-bottom: 20px;
}
.login-box-body{
    border: 1px solid #d2d6de;
}

.login-box{
     text-align: center;
 }

.btn-link{
     font-weight: 800;
 }

.export{
     float: right;
     margin-left: 5px;
     width: 5%;
     min-width: 75px;
 }

.export-lg{
  float: right;
  min-width: 154px;
  line-height: 16px;
  font-size: 14px;
  height: 30px;
  padding-right: 2px;
}

p.export{
    float: right;
    font-weight: 800;
    padding-top: 7px;
}



table{
  th{
    background-color: #a1a1a1;
    color: #ffffff;
    text-transform: uppercase;

    .datepicker &{
      background-color: initial;
      color: initial;
      text-transform: initial;
    }
  }
  tbody{
    td.primary{
        font-weight: bold;
    }
  }
}

.dataTables_filter{
  float: right;
}

.dataTables_paginate{
  float: right;
}

.pagination{
  li > a, li > span, li.previous > a, li.previous > span, li.next > a, li.next > span {
    font-size: 12px;
    font-weight: bold;
    color: #243958;
    border-radius: 500px;
    background-color: #efefef;
    border: none;
    margin: 0 1px 0 1px;
    -webkit-transition: background-color 0.4s, color 0.4s;
    -o-transition: background-color 0.4s, color 0.4s;
    -moz-transition: background-color 0.4s, color 0.4s;
    transition: background-color 0.4s, color 0.4s;
  }
  li.active > a, li.active > span {
    color: #fff;
    background-color: #a1a1a1;
  }
  .active > a, .active > a:hover, .active > a:focus, .active > span, .active > span:hover, .active > span:focus{
    background-color: #a1a1a1;
    border: none;
    outline: 0;
  }
  li > a:focus, li > span:focus{
    color: #243958;
    outline: 0;
  }
  li > a:hover, li > span:hover{
    color: #fff;
    background-color: #a1a1a1;
    -webkit-transition: background-color 0.4s, color 0.4s;
    -o-transition: background-color 0.4s, color 0.4s;
    -moz-transition: background-color 0.4s, color 0.4s;
    transition: background-color 0.4s, color 0.4s;
  }
  .disabled > span, .disabled > span:hover, .disabled > span:focus, .disabled > a, .disabled > a:hover, .disabled > a:focus{
    border: 1px solid #efefef;
  }
}

select[name="members_length"],
select[name="dataTable_length"]{
  webkit-appearance: none;
  moz-appearance: none;
  ms-progress-appearance: unset;
    min-width: 50px;
}

.elearning-section{
  padding-top:20px;
  select.form-control, input[type="search"]{
    -webkit-appearance: none !important;
    moz-appearance: none !important;
    border-radius: 500px !important;
    //background-color: #efefef;
    font-size: 15px !important;
    border-color: #efefef !important;
    padding-top: 5px !important;
    padding-left: 18px !important;
    padding-right: 18px !important;
    background: url('/images/dropdown.svg') no-repeat 92% !important;
  }

  input[type="search"]{
    padding-top: 2px;
    background: rgba(239, 239, 239, 100) url("../../../public/images/action/action_icon_search.png") no-repeat scroll 95% center / 15px 16px !important;
  }
}

select.form-control, input[type="search"]{
    border-radius: 500px;
    background-color: #efefef;
    border-color: #efefef;
    padding-top: 0;
    padding-left: 10px;
    padding-right: 18px;
}

.form-control:focus{
     border-color: #243958;
 }

.form-control.not-editable{
  border-radius: 500px;
  background-color: #efefef;
}


.auth-control{
     background-color: #ffffff;
 }

a.member-action{
    margin: 3px;
}

a.details-action{
    color: #90963a;
}

a.edit-action{
     color: #f2c56e;
 }

a.delete-action{
    color: #243958;
}

select.initial-select{
    border-radius: initial;
    background-color: #ffffff;
}

.btn-circle.btn-xl {
     width: 100px;
     height: 100px;
     border-radius: 50%;
     border-color: #90963a;
     background-color: #90963a;
     color: #ffffff;
    -webkit-transition: background-color 0.4s, border-color 0.4s;
    -o-transition: background-color 0.4s, border-color 0.4s;
    -moz-transition: background-color 0.4s, border-color 0.4s;
    transition: background-color 0.4s, border-color 0.4s;
 }
.btn-circle.btn-xl:hover,
.btn-circle.btn-xl:active{
     background-color: #243958;
     border-color: #243958;
     color: #ffffff;
     outline: none;
     -webkit-transition: background-color 0.4s, border-color 0.4s;
     -o-transition: background-color 0.4s, border-color 0.4s;
     -moz-transition: background-color 0.4s, border-color 0.4s;
     transition: background-color 0.4s, border-color 0.4s;
 }

.dashboard_icon{
     padding-top: 23.5%
}

.dashboard-item{
     color: #444444;
     font-size: 18px;
    text-align: center;
  padding-bottom: 20px;
 }
div.box-header{ text-align: center; }
.row.activity-bar {
  background-color: #243958;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 18px;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
  top: -25px;
}
//.activity-bar {
//  .nav-pills {
//    font-size: 14px;
//    display: flex;
//    justify-content: center;
//  }
//  .nav-pills > li > a {
//    border: none;
//    border-radius: 0;
//    background-color: #454545;
//    color: #fff;
//  }
//  .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus,
//  .nav-pills > li > a:hover, .nav-pills > li > a:focus{
//    border: none;
//    background: none;
//    color: #f2c56e;
//  }
//}
  .info-text{ color: #ffffff;}
  .info-number{ color: #ffffff; }
  .info-spacing{ padding: 8px; }
  ul.padded-list li{ padding: 10px; }
  h3.application-header {
    text-align: center;
    color: #243958;
    text-transform: none;
    padding-bottom: 10px;
  }
  p.sub-header {
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
  }
  em {
    font-size: small;
    text-transform: none;
  }
  .documents-list {
    background: #efefef;
    margin: 5px;
    min-height: 400px;
    overflow: hidden;
    padding: 25px;
    background: linear-gradient(to bottom, #e8e8e8, #fff);
  }
  .documents-header {
    color: #243958;
    padding-top: 10px;
  }
  input.form-control {
    border-radius: 500px;
  }
  input[type=number]{
    border-radius: 500px !important;
  }
  .panel {
    border-radius: 0;
  }
  textarea.form-control {
    border-radius: 20px;
  }
  .panel > .panel-heading {
    border-radius: 0;
    background-image: none;
    background-color: #243958;
    border-color: #243958;
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
  }
  .display-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
  .up-front {
    position: relative;
    z-index: 1;
  }
  .bg-light-green {
    background-color: #243958 !important;
    color: #fff !important
  }

  .tooltip-inner {
    white-space:nowrap;
    max-width:none;
  }

input[type='radio']:after {
  font-family: FontAwesome;
  padding-top: 1px;
  text-align: center;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  color: rgba(255,255,255,0);
  content: '\f00d';
  display: inline-block;
  visibility: visible;
  border: 1px solid #243958;
  outline-style: none;
  -webkit-transition: color 0.4s linear;
  -o-transition: color 0.4s linear;
  -moz-transition: color 0.4s linear;
  transition: color 0.4s linear;
}

input[type='radio']:checked:after {
  color: #243958;
  visibility: visible;
  outline-style: none;
  -webkit-transition: color 0.4s linear;
  -o-transition: color 0.4s linear;
  -moz-transition: color 0.4s linear;
  transition: color 0.4s linear;
}

input[type='checkbox']:after {
  font-family: FontAwesome;
  width: 16px;
  height: 16px;
  position: relative;
  top: -2px;
  left: -2px;
  background-color: #fff;
  color: rgba(255,255,255,0);
  content: "\f00c";
  display: inline-block;
  visibility: visible;
  border: 1px solid #243958;
  outline-style: none;
  -webkit-transition: color 0.4s linear;
  -o-transition: color 0.4s linear;
  -moz-transition: color 0.4s linear;
  transition: color 0.4s linear;
}

input[type='checkbox']:checked:after {
  color: #243958;
  visibility: visible;
  outline-style: none;
  -webkit-transition: color 0.4s linear;
  -o-transition: color 0.4s linear;
  -moz-transition: color 0.4s linear;
  transition: color 0.4s linear;
}

.question-explanation{
  padding: 10px;
}

@media (max-width: 991px){
  .main-header .navbar-custom-menu a{
    color: #243958;
  }
  .main-header .navbar-custom-menu a:hover{
    color: #264869;
  }
}
@media (max-width: 767px){
  .skin-green-light .main-header .navbar .dropdown-menu li a{
    color: #243958;
  }
  .skin-green-light .main-header .navbar .dropdown-menu li a:hover{
    color: #264869;
    background: none !important;
  }
}
@media (min-width: 768px){
  .sidebar-toggle{
    display:none;
  }
}
@keyframes type-red{
  1%, 100% {
    border-color: #243958 !important;
  }
  50% {
    border-color: #243958 !important;
  }
}

.jconfirm-buttons button{
  background-color: #EAC47A!important;
  border-color: #EAC47A!important;
  border-radius: 500px !important;
  color: #514F49!important;
  height: 31px;
  line-height: 1.22857143;
  margin-top: 12px;
  margin-bottom: 12px;
}

.jconfirm-box.jconfirm-type-red{
  border-top: solid 7px #243958 !important;
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

.quiz-step{
  .quiz_title{

  }
  .quiz_step_index{
    font-size: 15px;
    font-weight: 300;
    color: #787878;
    text-transform: none;
  }
  .quiz_sub_title{
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .question-container{
    margin-bottom: 30px;
  }
  .question-content{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  li.option{
    label{
      font-weight:400 !important;
    }
  }

  input[type='radio']:after {
    padding-top: 3px;
    font-size: 13px;
    width: 22px;
    height: 22px;
    border-radius: 18px;
    top: -4px;
    left: -3px;
    content: '\f111';
    border: 2px solid #243958;
  }

  input[type='radio']:checked:after {
    color: #243958;
  }

  input[type='checkbox']:after {
    width: 18px;
    height: 18px;
    top: -5px;
    left: -2px;
    content: "\f00c";
    border: 2px solid #243958;
  }

  input[type='checkbox']:checked:after {
    color: #243958;
  }
}

.step_question{
  label, strong, span{
    font-size: 17px;
  }
  .tf-statement{
    font-size:17px;
    font-weight:500;
  }
}


.elearning-center-col{
  float: none;
  margin-left: auto;
  margin-right: auto;
}
.vp-player-layout{
  width: inherit;
}
.quiz-result-title, .module-edit-title{
  text-transform: none;
  font-size: 28px;
  font-weight: 500;
}

.quiz-result-block{
  margin-top: 50px;
  div {
    padding-top: 20px;
  }
  ul {
    li{
      font-size:18px;
    }
    padding-bottom: 40px;
  }
}

.box-module-info{
  padding-bottom: 42px;
}
.exemption-section{
  padding-bottom: 87px;
}

fieldset.spacer{
  margin-top: 40px;
  margin-bottom: 30px;
}

.btn-tab{
  border-radius: 0;
  //border-color: #243958;
  margin-bottom: 0;
  margin-right: 6px;
  padding: auto;
  height: 50px;
  width: 120px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border-bottom: 0;
  &:hover{
    background-color: #243958;
    border-color: #243958;
    color: #f3f3f3;
    -webkit-transition: color 0.4s;
    -o-transition: color 0.4s;
    -moz-transition: color 0.4s;
    transition: color 0.4s;
  }
  &:focus{
    outline: none;
  }
}

.tab-elearning-inactive{
  background-color: #f3f3f3;
  border-color: #f3f3f3;
  color: #7a7a7a;
}

.tab-elearning-active{
  background-color: #ffffff;
  border-color: #f3f3f3;
  color: #243958;
}

.tab-elearning-new{
  background-color: #f1c375;
  border-color: #f1c375;
  color: #454545;
}

.tab-line{
  margin-top: 0;
}

.steps-tabs{
  margin-top: 25px;
}
.module-steps{
  padding-left:15px;
  padding-right: 15px;
  fieldset{
    margin-top: 30px;
    .step-section{
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 15px;
      .step-info {
        padding-left: 0;
      }
      .step-video{
        padding-right: 0;
      }
    }
  }
}

//iframe, video{
//  //position: absolute;
//  //top: 0;
//  //left: 0;
//  width: 100%;
//  height: 100%;
//  //border: 0;
//}

.resp-container{
  overflow: hidden;
  padding-top: 28%;
  position: relative;
  height: 350px;
  iframe {
    border: 0;
    //height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
.admin-question{
  label {
    font-weight: 400;
  }
}
.admin-question-box{
  min-height: 500px;
}

hr.elearning-divider{
  border: 0.5px solid #f3f3f3;
}
.btn-download-cert{
  padding: 0;
}

.bring-to-front {
  z-index: 1;
}